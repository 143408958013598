import React from 'react'

const Copyright = () => {
  return (
    <div className='flex text-center justify-center sm:py-[50px] py-[30px]'>
       <p className='text-[#b12929] sm:text-[18px] text-[12px] justify-center'>Copyright © 2023 <span className='font-bold'>Rishta.Money</span></p>
    </div>
  )
}

export default Copyright