import React from 'react'
import Headings from './Headings'

const About = () => {
  return (
    <div id='about' className='text-center py-10'>
        <Headings headings='About Us' className='mb-8' />
        <p class='lg:text-[18px] text-[14px] lg:w-[400px] md:w-[280px] w-[235px] text-justify mx-auto pt-[25px] text-[#646464]'>
          Rishta.Money takes pride in the positive impact we have had on the lives of countless individuals in rural India. By providing financial tools, fostering entrepreneurship, and promoting financial literacy, we are creating a ripple effect that transcends generations
          </p>
    </div>
  )
}

export default About
