import React from 'react'
import {FaInstagram, FaFacebookF,FaWhatsapp, FaPhone} from 'react-icons/fa'
import { AiOutlineMail } from 'react-icons/ai'
import {FiPhone} from 'react-icons/fi'

const Footer = () => {
  return (
    <div id='Footer' className='scroll-smooth justify-center sm:mx-28 gap-4 grid lg:grid-cols-3 md:grid-cols-3 px-[35px] pb-[30px]'>
        <div>
            <h1 className='text-[#B12929] pb-2 font-bold text-[40px]'>Rishta.Money</h1>
            <p className='pb-2 text-[#444444] text-[18px] md:w-[250px] w-[250px]'>7th Floor, BCC Shakti Tower, Faizabad Road, Lucknow (U.P) Pin- 226028</p>
            <p className='text-[#B12929] pt-[20px] font-bold'>CIN<span className='text-[#444444] font-normal'> : K32467WA7463PLC234334</span></p>
        </div>

        <div className='flex justify-around'>
        <div  className='pt-[20px] text-[#444444]'>
            <h1 className='text-[#B12929] font-bold pb-[8px] text-[23px]'>Company</h1>
            <ul>
                <li className='py-2 text-[16px]'>Home</li>
                <li className='py-2 text-[16px]'>Our Partners</li>
                <li className='py-2 text-[16px]'>Contact Us</li>
            </ul>
        </div>

        <div  className='pt-[20px] text-[#444444]'>
            <h1 className='text-[#B12929]  font-bold pb-[8px] text-[23px]'>Legal</h1>
            <ul>
                <li className='py-2 text-[16px]'>Privacy</li>
                <li className='py-2 text-[16px]'>Terms of Services</li>
                <li className='py-2 text-[16px]'>Disclaimer</li>
            </ul>
        </div>
        

        </div>
       <div className='pt-[20px] md:pl-[100px] justify-end'>
         <h1 className='text-[#B12929] font-bold text-[23px]'>Connect With Us</h1>
         <div className='text-[#b12929] flex justify-between md:w-[40%] w-[60%] my-6'>
            <FaFacebookF className='hover:text-[#DC2E2E] mr-[10px]' size={24}/>
            <FaInstagram className='hover:text-[#DC2E2E] mr-[10px]' size={24}/>
            <FaWhatsapp className='hover:text-[#DC2E2E] mr-[10px]' size={24}/>
         </div>

         <div className='flex items-center pr-[15px] pb-[10px]'>
            <FiPhone className='hover:text-[#DC2E2E] text-[#b12929]' size={18}/>
            <p className='pl-[10px] text-[#444444]'>+91-9919942299</p>
         </div>

         <div className='flex items-center pr-[15px]'>
            <AiOutlineMail className='hover:text-[#DC2E2E] text-[#b12929]' size={18}/>
            <p className='pl-[10px] text-[#444444]'>info@rishta.money</p>
         </div>
       </div>

        </div>
  )
}

export default Footer