import React from 'react';
import Apply from './Components/Apply';
import Copyright from './Components/Copyright';
import EmiCalci from './Components/EmiCalci';
// import Faq from './Components/Faq';
import FaqQuestions from './Components/FaqQuestions';
import Features from './Components/Features';
import Footer from './Components/Footer';
import Hero from './Components/Hero';
import Journey from './Components/Journey';
import Navbar from './Components/Navbar';
// import Offers from './Components/Offers';
import PersonalLoan from './Components/PersonalLoan';
import Testimonial from './Components/Testimonial';
import Usecase from './Components/Usecase';
import OffersSection from './Components/OffersSection';


function App() {
  return (
    <div >
     <Navbar/>
     <Hero/>
     <Features/>
     <OffersSection/>
     <PersonalLoan/>
     <Apply/>
     <Journey/>
     <Usecase/>
     <EmiCalci/>
     <FaqQuestions/>
     <Testimonial/>
     <Footer/>
     <Copyright/>
    </div>
  );
}

export default App;
