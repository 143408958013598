import React from 'react';

const FeaturesCard = ({img, para}) => {
  return (
    
        <div 
        className='bg-white/10 ease-out duration-500 group-hover:blur-sm shadow-[0px_0px_2px_0.1px]
        hover:!blur-none group-hover:scale-[0.85] hover:!scale-110 
        cursor-pointer px-8 py-12 justify-center align-middle rounded-xl '>
            <div className=''>
               <img src={img} className='mx-auto' alt=""></img>
            </div>
            <h3 className='text-primaryColor text-center md:w-[150px] marker:pt-10 font-bold' >{para}</h3>
          </div>

  )
}

export default FeaturesCard