import React, {useState} from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Headings from './Headings';
import apply1 from '../Images/Apply/apply1.png';
import apply2 from '../Images/Apply/apply2.png';
import apply3 from '../Images/Apply/apply3.png';
import apply4 from '../Images/Apply/apply4.png';
import apply5 from '../Images/Apply/apply5.png';

// import required modules
import { Pagination, Navigation } from "swiper";

const Apply = () => {

  return (
    <>
    <Headings headings="How To Apply on Rishta.Money" />
      <div className='h-[500px] sm:mx-[100px] mx-[30px] mb-[100px] overflow-auto scrollbar-thumb-primaryColor scrollbar-track-[#efefef] scrollbar-thin bg-[#efefef] '>
          {/* Register for Laptop */}
          <div className='sm:contents hidden'>
          <div className='sm:flex justify-center sm:px-[70px] gap-20 sm:py-[50px]'>
            <div className='align-middle gap-[50px] sm:py-[100px] py-[50px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor sm:text-[50px] text-[20px] pb-4'>Register</h1>  
              <p className='sm:w-[500px] sm:text-[19px]'>First step is to enter your name, mobile number and email id for login by entering OTP you will then have to fill bank details</p>
            </div>  
            <img src={apply1} alt="Register" />      
          </div>
          </div>

          {/* Register for Mobile */}
          <div className='sm:hidden'>
          <div className='sm:flex justify-center sm:px-[70px] gap-20 sm:py-[50px]'>
            <img src={apply1} alt="Register" /> 
            <div className='align-middle gap-[50px] sm:py-[100px] py-[50px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor sm:text-[50px] text-[20px] pb-4'>Register</h1>  
              <p className='sm:w-[500px] mr-[20px] sm:text-[19px]'>First step is to enter your name, mobile number and email id for login by entering OTP you will then have to fill bank details</p>
            </div>  
                 
          </div>
          </div>

          {/* Eligibility for Laptop */}
          <div className='sm:contents hidden'>
          <div className='flex justify-center px-[80px] gap-20 py-[50px]'>
            <img src={apply2} alt="Eligibility" /> 
            <div className='align-middle gap-[50px] py-[100px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor text-[50px] pb-4'>Eligibility</h1>  
              <p className='w-[500px] text-[19px]'>Enter a few basic details to check your eligibility and then we will proceed to next step.</p>
            </div>      
          </div>
          </div>

          {/* Eligibiliy for mobile */}
          <div className='sm:hidden'>
          <div className='sm:flex justify-center sm:px-[70px] gap-20 sm:py-[50px]'>
            <img src={apply2} alt="Eligibility" />   
            <div className='align-middle gap-[50px] sm:py-[100px] py-[50px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor sm:text-[50px] text-[20px] pb-4'>Eligibility</h1>  
              <p className='sm:w-[500px] mr-[20px] sm:text-[19px]'>Enter a few basic details to check your eligibility and then we will proceed to next step.</p>
            </div>  
               
          </div>
          </div>

          {/* Loan Details for Laptop */}
          <div className='sm:contents hidden'>
          <div className='flex justify-center px-[70px] gap-20 py-[50px]'>
            <div className='align-middle gap-[50px] py-[100px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor text-[50px] pb-4'>Select Loan Details</h1>  
              <p className='w-[500px] text-[19px]'>Enter loan amount and tenure to get the loan according to your needs.</p>
            </div>  
            <img src={apply3} alt="Register" />      
          </div>
          </div>

          {/* Loan Details for mobile */}
          <div className='sm:hidden'>
          <div className='sm:flex justify-center sm:px-[70px] gap-20 sm:py-[50px]'>
            <img src={apply3} alt="Eligibility" />   
            <div className='align-middle gap-[50px] sm:py-[100px] py-[50px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor sm:text-[50px] text-[20px] pb-4'>Select Loan Details</h1>  
              <p className='sm:w-[500px] mr-[20px] sm:text-[19px]'>Enter loan amount and tenure to get the loan according to your needs.</p>
            </div>  
               
          </div>
          </div>

          {/* KYC for Laptop */}
          <div className='sm:contents hidden'>
          <div className='flex justify-center px-[80px] gap-20 py-[50px]'>
            <img src={apply4} alt="Eligibility" /> 
            <div className='align-middle gap-[50px] py-[100px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor text-[50px] pb-4'>Complete Your KYC</h1>  
              <p className='w-[500px] text-[19px]'>Complete your video-KYC using PAN, Aadhaar and Selfie.</p>
            </div>      
          </div>
          </div>

           {/* KYC for Mobile*/}
           <div className='sm:hidden'>
          <div className='sm:flex justify-center sm:px-[70px] gap-20 sm:py-[50px]'>
            <img src={apply4} alt="Eligibility" />   
            <div className='align-middle gap-[50px] sm:py-[100px] py-[50px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor sm:text-[50px] text-[20px] pb-4'>Complete Your KYC</h1>  
              <p className='sm:w-[500px] mr-[20px] sm:text-[19px]'>Complete your video-KYC using PAN, Aadhaar and Selfie.</p>
            </div>  
               
          </div>
          </div>

          {/* Link Bank & Get Loan for Laptop */}
          <div className='sm:contents hidden'>
          <div className='flex justify-center px-[70px] gap-20 py-[50px]'>
            <div className='align-middle gap-[50px] py-[100px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor text-[50px] pb-4'>Link Bank & Get Loan Details</h1>  
              <p className='w-[500px] text-[19px]'>Enter bank details and set-up auto pay. Get instant money in your bank account.</p>
            </div>  
            <img src={apply5} alt="Register" />      
          </div>
          </div>

          {/* Link Bank & Get Loan for Mobile */}
          <div className='sm:hidden'>
          <div className='sm:flex justify-center sm:px-[70px] gap-20 sm:py-[50px]'>
            <img src={apply5} alt="Eligibility" />   
            <div className='align-middle gap-[50px] sm:py-[100px] py-[50px]'>
              <h1 className='font-montserrat font-semibold text-primaryColor sm:text-[50px] text-[20px] pb-4'>Link Bank & Get Loan Details</h1>  
              <p className='sm:w-[500px] mr-[20px] sm:text-[19px]'>Enter bank details and set-up auto pay. Get instant money in your bank account.</p>
            </div>  
               
          </div>
          </div>
          
      </div>
    </>
  )
}

export default Apply