import React, {useState} from 'react'
import {AiOutlineClose} from 'react-icons/ai'
import {HiMenuAlt1} from 'react-icons/hi';
import {IoIosArrowBack} from 'react-icons/io'
import { useNavigate } from 'react-router-dom';

const Navbar = () => {

    const navigate = useNavigate();

    function handleClick() {
      navigate("/");
    }
  
    function handleClick1() {
      navigate("/team");
    }
    function handleClick2() {
      navigate("/about");
    }

  const [nav, setNav] = useState(true)
  const handleNav = () => {
    setNav(!nav)
  }

  return (
    <div className='flex shadow-lg z-40 font-montserrat justify-between items-center h-[75px] mx-auto px-[20px] bg-white'>
        <h1 className='w-full md:text-3xl text-2xl font-montserrat font-bold text-primaryColor cursor-pointer' onClick={handleClick}>
          Rishta.Money
        </h1>
        <ul className='md:flex text-black hidden'>
            <li className='p-4 py-6 w-36 text-[13px] text-center hover:font-bold hover:border-b-2 border-[#b12929] cursor-pointer'>
              <p onClick={handleClick1}>Team</p>
            </li>
            <li className='p-4 py-6 w-36 text-[13px] text-center hover:font-bold hover:border-b-2 border-[#b12929] cursor-pointer'>
              <p onClick={handleClick2}>About Us</p>
            </li>
          <li className='p-4 py-6 w-36 text-[13px] text-center hover:font-bold hover:border-b-2 border-[#b12929] cursor-pointer'>
             <a href="#EmiCalc">Emi Calculator</a>
          </li>
          <li className='p-4 py-6 w-36 text-[13px] text-center hover:font-bold hover:border-b-2 border-[#b12929] cursor-pointer'>
             <a href="#Footer">Contact Us</a>
          </li>
          <li className='py-4 w-36 text-[13px] text-center'>
            <a href="https://app.rishta.money/" rel="noopener noreferrer">
              <button className='w-36 h-10 text-center align-middle hover:scale-110 ease-out duration-500 rounded-full text-[#fff] bg-[#B12929] font-semibold '>
                Log In
              </button>
            </a>
          </li>
        </ul>

        <div onClick={handleNav} className='block md:hidden'>
          {!nav ? <AiOutlineClose className='ml-[50px] ' size={22}/> : <HiMenuAlt1 className='ml-[50px] ' size={22}/> }  
        </div>

        <div className={!nav ? 'z-40 fixed font-montserrat left-0 top-0 w-[70%] h-full border-r-2 border-r-[#e2e2e2] bg-[#fff] ease-out duration-500' : 'fixed left-[-100%]' }>

          <h1 className='w-full text-2xl px-8 mt-4 pt-4 font-bold text-[#861212]' onClick={handleClick}>
            Rishta.Money
            </h1>

          <ul className='uppercase p-4'>
            <li className='p-4 mt-3 w-36 text-center rounded-full text-[#fff] bg-[#B12929] font-semibold'>
              <p className='scroll-smooth' onClick={handleClick1}>Team</p>
            </li>
            <li className='p-4 mt-3 w-36 text-center rounded-full text-[#fff] bg-[#B12929] font-semibold'>
              <p className='scroll-smooth' onClick={handleClick2}>About Us</p>
            </li>
            <li className='p-4 mt-3 w-36 text-center rounded-full text-[#fff] bg-[#B12929] font-semibold'>
              <a href="#EmiCalc" className='scroll-smooth'>Emi Calculator</a>
            </li>
            <li className='p-4 mt-3 w-36 text-center rounded-full text-[#fff] bg-[#B12929] font-semibold'>
              <a href="#Footer">Contact Us</a>
            </li>
            <li>
              <button className='p-4 mt-3 w-36 text-center rounded-full text-[#fff] bg-[#B12929] font-semibold '>
                <link rel="stylesheet" href="" />Log In
              </button>
            </li>
          </ul>

          <div onClick={handleNav} className='block md:hidden'>
            {<IoIosArrowBack className='mt-[300px] mx-[150px] fixed' size={22}/> }  
          </div>

        </div>
    </div>
  )
}


export default Navbar

// HiMenuAlt1