import React, {useState} from 'react'
import { FaChevronUp, FaChevronDown} from 'react-icons/fa'
import FaqQuestions from './FaqQuestions'

const Faq = ({Ques, ans}) => {

    const[expand, setExpand] = useState(false);

    const expandClass =  expand ? 'display' : 'hidden';
    const ansClass = `${expandClass} p-4`

  return (
    <div className='shadow rounder md:mx-[100px] mx-[30px] border-gray-100 mb-[20px]'>
        <div className='p-4 md:text-xl relative font-montserrat font-semibold' onClick={() => setExpand(!expand)}>
            <div>
            {Ques}
            </div>
            <button
                aria-label='question-expander'
                className='md:text-xl absolute top-0 right-0 p-4 focus:outline-none'
                onClick={() => setExpand(!expand)}
            >
                {
                    expand ?
                    <FaChevronUp className='text-black' size={15}/> 
                    :
                    <FaChevronDown size={15}/>
                }
            </button>
        </div>
        <div className={ansClass}>
            {ans}
        </div>
          
    </div>
  )
}

export default Faq