import React from 'react'
import Headings from './Headings'

const PersonalLoan = () => {
  return (
    <div className='md:px-16 py-28 px-12 text-justify justify-center '>
      <Headings
        headings = 'What is Personal Loan?'
      />
      <p className='text-[#6e6e6e] md:pt-7 md:px-36'>A Personal Loan is an unsecured loan that can help you meet your current financial needs.
      The loan does not require you to pledge any security or collateral and is available with minimum documentation.
      It can be used for anything, unlike product-specific loans like those for a home or a car.</p>
      <p className='text-[#6e6e6e] md:pt-7 pt-5 md:px-36'>You can use the funds from this loan for any legitimate financial need.
      Like any other loan, you must repay it accordance to the agreed terms with the bank.
      Normally this can include a few months to a few years in easy equated monthly instalments.</p>
    </div>
  )
}

export default PersonalLoan