import React, {useState} from 'react'
import { FaChevronUp, FaChevronDown} from 'react-icons/fa'

const Offers = ({sno, head, p1, p2, p3}) => {
  const[expand, setExpand] = useState(false);

    const expandClass =  expand ? 'display sm:w-[400px] ' : 'hidden';
    const ansClass = `${expandClass} sm:px-20 ease-out duration-500 px-8`

  return (
    <div className='shadow rounder mx-[20px] hover:my-8 ease-out duration-500 hover:gap-4 py-20 group hover:scale-110 border-gray-100 rounded-[20px] bg-primaryColor text-[#ffffff] mb-[18px]'>
    <div className='p-5 text-xl relative  cursor-pointer font-montserrat font-semibold' onClick={() => setExpand(!expand)}>
        <div className='sm:text-[60px] text-[40px]'>
        {sno}
        </div>
        <div className='sm:text-[25px] w-[200px] sm:pt-[50px] pt-[35px]'>
          {head}
        </div>
    </div>
    <div className={ansClass}>
        <ul className='list-disc font-montserrat'>
          <li>{p1}</li>
          <li>{p2}</li>
          <li>{p3}</li>
        </ul>
    </div>     
</div>
  )
}

export default Offers