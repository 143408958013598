import React from 'react'

const UsecaseIcons = ({icon, txt}) => {
  return (
    <div className='hover:scale-125 ease-out duration-500'>
        <img className='sm:px-12 sm:py-3 sm:content-between' width={140} src={icon} alt="" />
        <p className='text-center'>{txt}</p>
    </div>
    
  )
}

export default UsecaseIcons