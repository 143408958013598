import React from 'react'
import Headings from './Headings'
import icon1 from '../Images/usecase/icon1.png'
import icon2 from '../Images/usecase/icon2.png'
import icon3 from '../Images/usecase/icon3.png'
import icon4 from '../Images/usecase/icon4.png'
import icon5 from '../Images/usecase/icon5.png'
import icon6 from '../Images/usecase/icon6.png'
import icon7 from '../Images/usecase/icon7.png'
import icon8 from '../Images/usecase/icon8.png'

import UsecaseIcons from './UsecaseIcons'

const Usecase = () => {
  return (
    <div className='font-montserrat py-20'>
      <div className='mx-14'>
        <Headings headings='Usecases of Personal Loan'/>
      </div>
      
      <div className='sm:mx-40 mx-16 py-8 grid sm:grid-cols-4 gap-20 grid-cols-2 justify-items-center content-center'>
        <UsecaseIcons icon={icon1} txt='Car Loan' />
        <UsecaseIcons icon={icon2} txt='Travel Loan' />
        <UsecaseIcons icon={icon3} txt='Bike Loan' />
        <UsecaseIcons icon={icon4} txt='Mobile Loan' />
      </div>

      <div className='sm:mx-40 mx-16 py-8 grid sm:grid-cols-4 gap-20 grid-cols-2 justify-items-center'>
        <UsecaseIcons icon={icon5} txt='Marriage Loan' />
        <UsecaseIcons icon={icon6} txt='Used Car Loan' />
        <UsecaseIcons icon={icon7} txt='Used Bike Loan' />
        <UsecaseIcons icon={icon8} txt='Laptop Loan' />
      </div>
        
    
    </div>
  )
}

export default Usecase