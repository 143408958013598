import React from 'react'

const JourneyCard = ({heading, para}) => {
  return (
    <div className='font-montserrat'>
        <h1 className='text-[#b12929] text-[32px] font-bold md:text-[46px]'>
            {heading}
        </h1>
        <p className='md:text-[20px] md:px-0 px-2 text-[14px]'>
            {para}
        </p>
    </div>
  )
}

export default JourneyCard