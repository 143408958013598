import React from 'react'
import Navbar from '../Components/Navbar'
import Team from '../Components/Team'
import Footer from '../Components/Footer'

export default function TeamLayout() {
  return (
    <div>
      <Navbar/>
      <Team/>
      <Footer/>
    </div>
  )
}
