import React from 'react'


const Headings = ({headings}) => {
  return (
    <div className='text-center'>
        <h1 className='font-montserrat text-[28px] mx-auto md:text-[40px] font-bold pb-[22px] md:pb-[40px]'>
            {headings}
        </h1>
    </div>
  )
}

export default Headings