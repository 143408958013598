import React from 'react'
import Faq from './Faq'
import Headings from './Headings'

const FaqQuestions =  () => {
  return (
    <div className='py-[80px]'>
        <Headings
           headings='Frequently Asked Questions'
        />
        
        <Faq
         Ques ="What do you want"
         ans = "nothing"
        />

        <Faq
         Ques ="What do you want"
         ans = "nothing"
        />

       <Faq
         Ques ="What do you want"
         ans = "nothing"
        />

        <Faq
         Ques ="What do you want"
         ans = "nothing"
        />

    </div>
  )
}

export default FaqQuestions