import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import TeamLayout from "../Layouts/TeamLayout";
import AboutLayout from "../Layouts/AboutLayout";

export const router = createBrowserRouter([
    { path: "/",
      element: <App />
    },
    { path: "/team",
      element: <TeamLayout/>
    },
    {
        path: "/about",
        element: <AboutLayout/>
    }
]);