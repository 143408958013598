import React from 'react'
import Headings from './Headings'
import Offers from './Offers'

const OffersSection = () => {
  return (
    <div className='py-[150px]'>
      <Headings
      headings='What We Offer...'
      />
      <div className='md:flex pt-12 justify-center text-white'>
        <Offers
            sno='O1'
            head='Interest Rate and Charges'
            p1='Interest Rate: 9.9% - 45% p.a.'
            p2='Loan Amount: Up to ₹20 Lakh'
            p3='Tenure: upto 72 months'
        />
        <Offers
            sno='O2'
            head='Eligibility Criteria'
            p1='PAN card holder'
            p2='Salaried or Self-employed'
            p3='Good CIBIL score*'
        />
        <Offers
            sno='O3'
            head='Documents Required'
            p1='To complete your KYC, you just need your.'
            p2='Pan Card'
            p3='Aadhar Card'
        />
      </div>
    </div>
  )
}

export default OffersSection