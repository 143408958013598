import React from 'react'
import heroimg from '../Images/hero_img.png'

const Hero = () => {
  return (
    <div className='md:flex justify-between z-0 grid md:grid-cols-2 sm:grid-cols-2 items-center md:max-w-[1440px] md:pt-[35px] pt-[25px] mx-auto md:px-[100px] px-[30px]'>
        <div className='text-[#B12929]'>
            <h1 className='font-bold lg:text-[84px] sm:text-[50px] text-[40px]'>Rishta.Money</h1>
            <p className='lg:text-[34px] text-[22px] lg:w-[400px] w-[250px] text-justify'>एक अटूट रिश्ता आपकी हर छोटी जरूरत के लिए |</p>
            <p className='lg:text-[18px] text-[14px]  lg:w-[400px] md:w-[280px] w-[235px] text-justify pt-[25px] text-[#646464]'>Rishta.Money is a pioneering financial institution committed to fostering financial inclusion in the rural heartlands of India. 
            With a mission to empower individuals and communities through accessible and tailored financial services, we strive to bridge the gap between urban and rural financial ecosystems. 
            Our vision is to create a robust financial network that uplifts the socio-economic landscape of rural India.</p>
            <button className='w-36 mt-[25px] p-4 text-center rounded-full text-[#fff] bg-[#B12929] font-semibold hover:scale-110 ease-out duration-500 '>Apply Now</button>

            <div className='flex md:pt-[20px] pt-[20px]'>
                <div className='pr-[50px]'> 
                    <ul >
                        <li className='font-bold lg:text-[45px] text-[35px]'>100+</li>
                        <li className='lg:text-[21px] text-[17px] w-12'>Satisfied Customers</li>
                    </ul>
                </div>
                <div> 
                    <ul>
                        <li className='font-bold  lg:text-[45px] text-[35px]'>5x</li>
                        <li className='lg:text-[21px] text-[17px] md:w-36 w-28'>More Efficient Workflow</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='md:p-0 pt-[25px]'>
            <img className='md:w-[200] w-[100] lg:w-[200]' src={heroimg} alt="" />
        </div>
    </div>
  )
}

export default Hero