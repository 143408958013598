
import React, {useState} from 'react';
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io'
import {FaInstagram, FaFacebookF,FaLinkedin} from 'react-icons/fa'
import Headings from './Headings';

// Import Swiper styles

import img1 from '../Images/Team/p1.jpg'

const Team = () => {
  const [currIndex, setCurrIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currIndex - 1;
    setCurrIndex(newIndex);
  }

  const nextSlide = () => {
    const isLastSlide = currIndex === slides.length-1;
    const newIndex = isLastSlide ? 0 : currIndex + 1;
    setCurrIndex(newIndex);
  }

  const goToSlide = (slideIndex) => {
    setCurrIndex(slideIndex);
  }

  const slides = [
    {
      url : img1,
      head: "John Doe",
      para:"CEO, Rishta.Money",
      facebook:'https://www.facebook.com',
      instagram:'https://www.instagram.com',
      linkedin:'https://www.linkedin.com'

    },
    {
      url : img1,
      head: "Jane Smith",
      para:"CFO, Rishta.Money",
      facebook:'https://www.facebook.com',
      instagram:'https://www.instagram.com',
      linkedin:'https://www.linkedin.com'

    },
    {
      url : img1,
      head: "Bob Johnson",
      para:"CTO, Rishta.Money",
      facebook:'https://www.facebook.com',
      instagram:'https://www.instagram.com',
      linkedin:'https://www.linkedin.com'

    },
  ]

  return (
    <div id='OurTeam' className='text-center py-10'>
      <Headings headings='Our Team' className='mb-8' />

    <div className='sm:flex font-montserrat justify-center sm:mx-[120px] sm:my-[100px] my-[60px] py-16 px-4 relative'>
        {/* Left */}
        <div className='sm:w-[440px] content-center'>
          <img width={400} src={slides[currIndex].url} alt="" />
        </div>

        {/* Right */}
        <div className='sm:pl-20'>
          <div className='flex pt-[20px] justify-between'>
            <h1 className='font-bold sm:text-[45px] text-[20px] sm:pr-[200px] md:w-[500px] w-[200px]'>{slides[currIndex].head}</h1>
            <div className='flex sm:py-6'>
              <div className='text-2xl sm:pr-4 pr-3 cursor-pointer text-[#7c7c7c] hover:text-[#000] ' onClick={prevSlide}>
                <IoIosArrowBack />
              </div>
              <div className='text-2xl cursor-pointer text-[#7c7c7c] hover:text-[#000]' onClick={nextSlide}>
               <IoIosArrowForward/>
              </div>
            </div>
          </div>
          <div>
            <h1 className='sm:text-[16px] text-[13px] text-[#7c7c7c]'> {slides[currIndex].comp}</h1>
            <p className='text-justify sm:pt-6 pt-3 sm:w-[500px] sm:text-[20px] text-[13px] p-5'>{slides[currIndex].para}</p>
            <div className='text-[#b12929] flex justify-between sm:w-[30%] w-[50%] sm:my-6 my-4'>
              <a href={slides[currIndex].facebook} target='_blank' rel='noreferrer'><FaFacebookF className='hover:text-[#DC2E2E] mr-[10px]' size={20}/></a>
              <a href={slides[currIndex].instagram} target='_blank' rel='noreferrer'><FaInstagram className='hover:text-[#DC2E2E] mr-[10px]' size={20}/></a>
              <a href={slides[currIndex].whatsapp} target='_blank' rel='noreferrer'><FaLinkedin className='hover:text-[#DC2E2E] mr-[10px]' size={20}/></a>
            </div>
            <div className='flex sm:pt-12 sm:gap-6 gap-4 sm:justify-end justify-start sm:py-6 py-3'>
              {slides.map((slide, slideIndex) => (
              <div key={slideIndex} onClick={() => goToSlide(slideIndex)} className='text-2xl cursor-pointer'>
              <img width={40} className='rounded-lg hover:scale-125' src={slides[slideIndex].url} alt="" />
              </div>
            ))}
            </div>
          </div>
        </div>
      
    </div>
    </div>

  )
  
}

export default Team
