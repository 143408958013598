import React from 'react'
import Headings from './Headings'
import JourneyCard from './JourneyCard'

const Journey = () => {
  return (
    <div className='bg-[#efefef] md:py-28 py-20 max-w-[#1440px] text-center'>
      <Headings 
          headings='Our Journey so far'
      />
      <div className='grid md:grid-cols-4 grid-cols-2 gap-6 md:gap-4 '>
        <JourneyCard
        heading = '10K+'
        para ='Customers served'
        />
         <JourneyCard
        heading = '15K+'
        para ='Loans Sanctioned'
        />
         <JourneyCard
        heading = '121Cr+'
        para ='Loan amount disbursed'
        />
         <JourneyCard
        heading = '10L+'
        para ='Active Loans'
        />
      </div>

    </div>
  )
}

export default Journey