import React, {useState} from 'react'
import {Doughnut} from 'react-chartjs-2';
import Typography from '@mui/material/Typography';
import Headings from './Headings';
import {Chart, ArcElement, Tooltip, Legend} from 'chart.js'
import { TextField } from '@mui/material';
Chart.register(ArcElement, Tooltip, Legend);

const EmiCalci = () => {

  const [pAmount, setpAmount] = useState(10000);
  const [interest, setInterest] = useState(12);
  const [duration, setDuration] = useState(12);
  const intMax = 100;
  const maxDuration = 360;
  
  const intr = interest/1200;
  const _emi = duration ?  (pAmount * intr / (1 - (Math.pow(1/(1 + intr), duration)))) : 0;
  const emi = duration ?  Math.round(pAmount * intr / (1 - (Math.pow(1/(1 + intr), duration)))) : 0;
  const totalAmt = Math.round(duration * _emi);
  var TotalAmountOfCredit = Math.round((_emi / intr) * (1 - Math.pow((1 + intr), (-duration))));
  const TotalAmountOfInterest =  Math.round(totalAmt - TotalAmountOfCredit);

  // Flat interest calculator
  // const principal = pAmount;
  // const TotalAmountOfInterest = principal * (interest/100) * (duration/12);
  // const totalAmt = Number(principal) + Number(TotalAmountOfInterest);
  // const emi = Math.round(totalAmt / duration);

  return (
    <div id='EmiCalc' className='scroll-smooth sm:py-20 py-4 font-montserrat'>
      <Headings headings='EMI Calculator'/>
      <div className='sm:flex sm:px-20 px-8 gap-8 justify-center'>

        <div className='sm:my-20 my-8 sm:w-[500px] sm:h-[375px] rounded-md align-middle shadow-2xl sm:p-16 p-4'>
          <div>
            <Typography gutterBottom><strong>Loan Amount</strong></Typography>
              <TextField
              type='number'
              id="outlined-basic" 
              label="Enter an amount" 
              variant="outlined"
              defaultValue={pAmount}
              valueLabelDisplay="auto"
              onChange={(event) => {setpAmount(event.target.value);}}
              />

          </div>
          <div>
            <Typography gutterBottom><strong>Interest Rate %</strong></Typography>
              <TextField
              type='number'
              id="outlined-basic" 
              label="Enter annual rate" 
              variant="outlined"
              defaultValue={interest}
              valueLabelDisplay="auto"
              onChange={(event) => {setInterest(event.target.value);}}
              max = {intMax}
              />
          </div>
          <div>
            <Typography gutterBottom><strong>Tenure</strong></Typography>
              <TextField
              type='number'
              id="outlined-basic" 
              label="Number of months" 
              variant="outlined"
              defaultValue={duration}
              valueLabelDisplay="auto"
              onChange={(event) => {setDuration(event.target.value);}}
              max = {maxDuration}
              />
          </div>
        </div>

        {/* amount panel */}
        <div className='justify-center text-center'>
          <div className='sm:px-40 px-8 justify-center'>
            <Doughnut data = {{
                    labels: ['Interest Amount', 'Principal Amount'],
                    datasets: [{
                      data: [TotalAmountOfInterest, pAmount],
                      backgroundColor: ['#b12929','lightgrey'],
                      borderWidth:1
                    }]
                  }
                }
            />
          </div>
          <p className='sm:text-[30px] text-[20px] sm:pt-10 pt-6'>Estimated Payment</p>
          <p className='font-bold sm:text-[55px] text-[45px]'>{totalAmt ? totalAmt : 0}</p>
          <div className='flex justify-center sm:px-12 gap-8'>
            <p className='sm:text-[22px] text-[16px] w-[250px]'><span className='font-bold sm:text-[20px] text-[14px]'>Monthly EMI: </span>{emi}</p>
            <p className='sm:text-[22px] text-[16px] w-[250px]'><span className='font-bold sm:text-[20px] text-[14px]'>Total Interest: </span>{TotalAmountOfInterest}</p>
          </div>
          
        </div>
      </div>
    </div>
  )
} 

export default EmiCalci


// Disable scroll on number input field
document.addEventListener("wheel", function(event){
  if(document.activeElement.type === "number"){
      document.activeElement.blur();
  }
});