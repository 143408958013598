import React from 'react'
import Headings from './Headings'
import img1 from '../Images/hero_img.png'
import icon1 from '../Images/icon1.png'
import icon2 from '../Images/icon2.png'
import icon3 from '../Images/icon3.png'
import icon4 from '../Images/icon4.png'
import icon5 from '../Images/icon5.png'
import FeaturesCard from './FeaturesCard'

const Features = () => {
  return (
    
    <div>
      <div className='pt-[50px]'>
      <Headings
      headings='Features'
      />
      </div>
      <div className='lg:flex mx-4 grid gap-1 align-middle justify-center text-center font-montserrat group'>          
          <FeaturesCard
          img={icon1}
          para='Instant disbursal'
          />
          <FeaturesCard
          img={icon1}
          para='100% Paperless'
          />
          <FeaturesCard
          img={icon5}
          para='Flexible Emi Options'
          />
          <FeaturesCard
          img={icon2}
          para='Minimal Documentation'
          />
          <FeaturesCard
          img={icon4}
          para='No Collateral Damage'
          />
          <FeaturesCard
          img={icon3}
          para='Get Loan Amount of Your Choice'
          />   
      </div>
    </div>
  )
}

export default Features